<template>
  <MainLayout>
    <template>
      <div class="keep__title">
        <p class="keep__text">
          Сводная информация:
          {{ sourceObject.results ? sourceObject.results.name : "" }}
        </p>
      </div>
      <TableFilters :queries="queries"> </TableFilters>
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:sales>
        <Basic_Win8_Style :loading="isDateLoaded" :table-data="GetDataSales">
          <template v-slot:item.0.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.1.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.2.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.3.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.4.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.5.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
        </Basic_Win8_Style>
        <p
          v-if="!isDateLoaded"
          style="margin: 0 16px;color: #505a6d; font-weight: bold"
        >
          На детских садах
        </p>
        <Basic_Win8_Style
          :table-data="GetDataSalesKindergardens"
          :loading="isDateLoaded"
        >
          <template v-slot:item.0.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.1.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.2.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.3.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
        </Basic_Win8_Style>
        <DashboardInfopanel :loading="isDateLoaded"></DashboardInfopanel>
      </template>
      <template v-slot:objects>
        <Basic_Win8_Style :table-data="getDataObjects" :loading="isDateLoaded">
          <template v-slot:item.0.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.1.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.2.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.3.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.4.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.5.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
        </Basic_Win8_Style>
      </template>
      <template v-slot:users>
        <Basic_Win8_Style :loading="isDateLoaded" :table-data="getDataUsers">
          <template v-slot:item.0.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.1.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
          <template v-slot:item.2.value="{ item }">
            <div class="wrapper">
              <div class="title">
                {{ item.title }}
              </div>
              <br />
              <div class="value">
                {{ item.value }}
              </div>

              <br />
            </div>
          </template>
        </Basic_Win8_Style>
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import Basic_Win8_Style from "@/components/tables/Basic_Win8_Style";
import TableFilters from "@/components/tables/TableFilters";
import DashboardInfopanel from "@/views/MainPages/dashboardInfopanel";

import * as query from "@/helpers/query";
import moment from "moment/moment";

export default {
  name: "infopanel",
  components: {
    SlideLayout,
    MainLayout,
    Basic_Win8_Style,
    TableFilters,
    DashboardInfopanel
  },
  data() {
    return {
      today: null,
      queries: query.infopanel,
      slideTabs: [
        {
          text: "Продажи",
          value: "sales"
        },
        {
          text: "Объекты",
          value: "objects"
        },
        {
          text: "Пользователи",
          value: "users"
        }
      ]
    };
  },
  computed: {
    GetDataSales() {
      return [
        {
          title: "Общее количество продаж",
          value: this.sourceObject?.total_count
            ? this.sourceObject?.total_count
            : "0"
        },
        {
          title: "На детских садах",
          value: this.sourceObject?.total_kindergarten?.count
            ? this.sourceObject?.total_kindergarten?.count
            : "0"
        },
        {
          title: "На коммерческих объектах",
          value: this.sourceObject?.total_not_kg_count
            ? this.sourceObject?.total_not_kg_count
            : "0"
        },
        {
          title: "Общая сумма продаж, руб.",
          value: this.sourceObject?.total_amount
            ? this.sourceObject?.total_amount
            : "0"
        },
        {
          title: "На детских садах, руб.",
          value: this.sourceObject?.total_kindergarten?.amount
            ? this.sourceObject?.total_kindergarten?.amount
            : "0"
        },
        {
          title: "На коммерческих объектах, руб.",
          value: this.sourceObject?.total_not_kg_amount
            ? this.sourceObject?.total_not_kg_amount
            : "0"
        }
      ];
    },
    GetDataSalesKindergardens() {
      return [
        {
          title: "Продажи доступов по карте",
          value: this.sourceObject?.total_kindergarten?.count_card
            ? this.sourceObject?.total_kindergarten?.count_card
            : "0"
        },
        {
          title: "Продажи доступов по приложению",
          value: this.sourceObject?.total_kindergarten?.count_app
            ? this.sourceObject?.total_kindergarten?.count_app
            : "0"
        },
        {
          title: "Сумма продаж доступов по картам, руб.",
          value: this.sourceObject?.total_kindergarten?.amount_card
            ? this.sourceObject?.total_kindergarten?.amount_card
            : "0"
        },
        {
          title: "Сумма продаж доступов по приложению, руб.",
          value: this.sourceObject?.total_kindergarten?.amount_app
            ? this.sourceObject?.total_kindergarten?.amount_app
            : "0"
        }
        // {
        //   type: "kindergarden"
        // }
      ];
    },
    getDataUsers() {
      return [
        {
          title: "Общее количество пользователей",
          value: this.allKindergardenUsers + this.allObjectsUsers
        },
        {
          title: "Пользователи детских садов",
          value: this.allKindergardenUsers
        },
        {
          title: "Пользователи коммерческих объектов",
          value: this.allObjectsUsers
        }
      ];
    },

    getDataObjects() {
      return [
        {
          title: "Общее количество объектов",
          value: this.sourceObjects?.count + this.sourceKindergarden?.count
        },
        {
          title: "Детские сады",
          value: this.sourceKindergarden?.count
        },
        {
          title: "Коммерческие объекты",
          value: this.sourceObjects?.count
        },
        {
          title: "Общее количество контроллеров",
          value: this.allKindergardenControllers + this.allObjectsControllers
        },
        {
          title: "Контроллеры на детских садах",
          value: this.allKindergardenControllers
        },
        {
          title: "Контроллеры на коммерческих объектах",
          value: this.allObjectsControllers
        }
      ];
    },
    allKindergardenControllers() {
      let result = 0;
      for (let i = 0; i < this.sourceKindergarden.count; i++) {
        if (this.sourceKindergarden?.results[i]?.controllers_count) {
          result += this.sourceKindergarden?.results[i]?.controllers_count;
        }
      }
      return result;
    },
    allObjectsControllers() {
      let result = 0;
      for (let i = 0; i < this.sourceObjects.count; i++) {
        if (this.sourceObjects?.results[i]?.controllers_count) {
          result += this.sourceObjects?.results[i]?.controllers_count;
        }
      }
      return result;
    },
    allObjectsUsers() {
      let result = 0;
      for (let i = 0; i < this.sourceObjects.count; i++) {
        if (this.sourceObjects?.results[i]?.users_count) {
          result += this.sourceObjects?.results[i]?.users_count;
        }
      }
      return result;
    },
    allKindergardenUsers() {
      let result = 0;
      for (let i = 0; i < this.sourceKindergarden.count; i++) {
        if (this.sourceKindergarden?.results[i]?.users_count) {
          result += this.sourceKindergarden?.results[i]?.users_count;
        }
      }
      return result;
    },
    sourceObject() {
      return this.$store.getters.getDealerCount;
    },
    sourceObjects() {
      return this.$store.getters.getPageObjectsList;
    },
    sourceKindergarden() {
      return this.$store.getters.getPageKindergartensList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.today = moment(new Date()).format("YYYY");

    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        year: this.today
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchDealerCountWidgetPage").finally(() => {
      this.isDateLoaded = false;
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageObjectList").finally(() => {
      this.$store.dispatch("fetchPageKindergartensList").finally(() => {
        this.isDateLoaded = false;
      });
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;

      this.$store.dispatch("fetchDealerCountWidgetPage").finally(() => {
        this.$store.dispatch("fetchDealerDate").finally(() => {
          this.isDateLoaded = false;
        });
      });
    }
    next();
  }
};
</script>

<style scoped>
.title {
  color: #505a6d;
  -webkit-box-shadow: 0 1px 3px rgba(37, 65, 106, 0.1) !important;
  box-shadow: 0 1px 0px rgba(37, 65, 106, 0.1) !important;
  margin: 12px 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: initial;
}
.value {
  color: #505a6d;
  align-self: center;
  font-size: 32px;
  font-weight: bold;
}
.keep__title {
  padding: 30px 20px 0px 40px !important;
}
</style>
