var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"widget"},[_c('ChartLayout',{attrs:{"title":"Динамика продаж за год:","needReload":_vm.needDateReload.payment},scopedSlots:_vm._u([{key:"widget",fn:function(){return [_c('MixedCharts',{attrs:{"labels":_vm.user_labels,"data":[
          {
            type: 'bar',
            label: 'Приложений',
            data: _vm.user_dates.registered,
            fill: true,
            borderColor: 'rgb(54, 133, 235)',
            backgroundColor: 'rgb(149,194,61)',
            order: 0
          },
          {
            type: 'bar',
            label: 'Карт',
            data: _vm.user_dates.unconfirmed,
            fill: true,
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgb(39,45,83)',
            order: 1
          }
        ]}})]},proxy:true}],null,false,3056317269)}),_c('div',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(this.$store.getters.getDealerWidgets)+" "+_vm._s(this.user_labels)+" ")]),_c('div',{staticStyle:{"display":"none"}},[_c('PieCharts')],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }