<template>
  <div class="">
    <div
      class="d-flex align-center justify-center"
      v-if="tableData.length > 4 && loading"
    >
      <v-progress-circular indeterminate size="78" />
    </div>
    <div v-if="tableData.length == 4 && !loading" class="kindergarden_wrap">
      <div v-for="(item, ind) in tableData" :key="ind">
        <div class="item">
          <slot :name="'item.' + ind" v-bind:item="item">
            <div
              v-for="(value, key, index) in item"
              :key="index"
              style="width: 100%"
            >
              <slot
                :name="'item.' + ind + '.' + key"
                v-bind:item="item"
                v-bind:keyName="key"
                v-bind:value="value"
              >
              </slot>
              <div></div>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div v-else class="wrap">
      <div v-for="(item, ind) in tableData" :key="ind">
        <div class="item">
          <slot :name="'item.' + ind" v-bind:item="item">
            <div
              v-for="(value, key, index) in item"
              :key="index"
              style="width: 100%"
            >
              <slot
                :name="'item.' + ind + '.' + key"
                v-bind:item="item"
                v-bind:keyName="key"
                v-bind:value="value"
              >
              </slot>
              <div></div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Basic_Win8_Style",
  props: {
    tableData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style scoped>
.item {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 7px rgba(37, 65, 106, 0.25);
  text-align: center;
  align-items: center;
  margin: 10px;
  height: 90%;
}

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  padding: 0 0 1rem 0px;
}
.kindergarden_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  max-width: 66.66%;
}
@media (max-width: 749px) {
  .wrap {
    display: block;
    width: 100%;
  }
  .kindergarden_wrap {
    display: block;
    max-width: 100%;
  }
}
</style>
