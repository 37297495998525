import { render, staticRenderFns } from "./Basic_Win8_Style.vue?vue&type=template&id=9fd002c0&scoped=true&"
import script from "./Basic_Win8_Style.vue?vue&type=script&lang=js&"
export * from "./Basic_Win8_Style.vue?vue&type=script&lang=js&"
import style0 from "./Basic_Win8_Style.vue?vue&type=style&index=0&id=9fd002c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fd002c0",
  null
  
)

export default component.exports